<template>
  <b-modal
    :id="localModalId"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('select_list') }}</span>
      </h2>
      <p>{{ $t('select_or_create_a_new_list') }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="default-tabs">
        <!-- LIST TAB -->
        <b-tab :title="$t('existing_lists')">
          <div v-if="targetings.length === 0 && loader">
            <Loader
              :size="'big'"
            />
          </div>
          <div v-else-if="targetings.length === 0">
            <p>{{ $t('no_results_found') }}</p>
          </div>
          <div v-else>
            <div>
              <div
                class="d-flex targeting-item flex-wrap align-items-center p-2"
                :class="{
                  selected: selected.id === targeting.id,
                  inactive: targeting.status !== 'ACTIVE'
                }"
                v-for="(targeting, index) in targetings"
                :key="index"
                @click="handleClick(targeting)"
                :id="`targeting-${index}`"
              >
                {{ targeting.name }}
                <b-tooltip :target="`targeting-${index}`" :title="$t('list_with_error')" v-if="targeting.status !== 'ACTIVE'"></b-tooltip>
              </div>
            </div>
            <div class="text-center mt-3">
              <Button
                type="button"
                class="btn btn-primary btn-sm"
                @click="loadMore"
                :loading="loader"
                v-if="isLoadMoreVisible"
              >
                {{ $t('load_more') }}
              </Button>
            </div>
            <div class="text-center mt-3">
              <Button
                type="button"
                class="btn btn-primary"
                @click="select"
                :disabled="!selected"
              >
                {{ $t('select2') }}
              </Button>
            </div>
          </div>
        </b-tab> <!-- LIST TAB -->
        <!-- CREATE TAB -->
        <b-tab title="Criar nova">
          <div class="text-start">
            <UpsertCustomTargetingForm
              :advertiser="advertiser"
              :type="type"
              @custom-targeting-created="handleCreated"
              @upsert-custom-targeting-error="handleUpsertError"
            />
          </div>
        </b-tab> <!-- CREATE TAB -->
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import UpsertCustomTargetingForm from '@/components/custom-targetings/UpsertCustomTargetingForm'

export default {
  name: 'SelectCustomTargetingModal',
  props: ['advertiser', 'modalId', 'type', 'withGlobal'],
  components: {
    AlertWraper,
    Button,
    Loader,
    UpsertCustomTargetingForm
  },
  data () {
    return {
      loader: false,
      targetings: [],
      content: null,
      content_type: 'error',
      isLoadMoreVisible: false,
      page: 1,
      selected: '',
      tabIndex: 0
    }
  },
  computed: {
    /**
     * Modal id
     */
    localModalId () {
      return this.modalId ? this.modalId : 'create-custom-targeting-modal'
    }
  },
  methods: {
    /**
     * Get targetings
     */
    async getCustomTargetings () {
      this.loader = true
      try {
        const targetings = await CustomTargetingsService.getCustomTargetings({
          advertiserId: this.advertiser.id,
          types: [this.type],
          page: this.page,
          withGlobal: !!this.withGlobal
        })
        this.targetings = [...this.targetings, ...targetings.data]
        this.isLoadMoreVisible = targetings.meta.current_page !== targetings.meta.last_page
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Handle click
     */
    handleClick (targeting) {
      if (targeting.status !== 'ACTIVE') return
      this.selected = targeting
    },
    /**
     * Handle created
     */
    handleCreated (targeting) {
      this.tabIndex = 0
      this.targetings = []
      this.page = 1
      this.selected = ''
      this.getCustomTargetings()
    },
    handleUpsertError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Hidden
     */
    hidden () {
      this.targetings = []
      this.page = 1
      this.selected = ''
      this.content = null
      this.tabIndex = 0
    },
    loadMore () {
      this.page++
      this.getCustomTargetings()
    },
    select () {
      if (!this.selected) return
      this.$emit('custom-targeting-selected', this.selected)
      this.$bvModal.hide(this.localModalId)
    },
    /**
     * Shown
     */
    shown () {
      this.getCustomTargetings()
    }
  }
}
</script>

<style lang="scss" scoped>
.targeting-item:hover, .targeting-item.selected {
  cursor: pointer;
  background: #eee;
}
.targeting-item.inactive {
  background: #eee;
  opacity: 0.3;
}
</style>
